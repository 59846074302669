import { Routes, Route, Outlet } from 'react-router-dom'
import { PORTAL } from 'routes/portal'
import RiskRegister from "../pages/risks/risk-register";

export default function RiskRoutes() {
    console.log('RISK')
  return (
    <>
      <Routes>
        <Route index element={<RiskRegister />} />
        <Route
          path={PORTAL.RISK_REGISTER.replace(PORTAL.RISK, '')}
          element={<RiskRegister />}
        />
      </Routes>
      <Outlet />
    </>
  )
}
