import { useEffect, useState, useContext } from "react";
import { MagnifyingGlassIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import PageHeader from "components/page-header";
import Table from "components/table";
import { sub, add, compareDesc, isAfter } from "date-fns";
import { useTextFormatHook } from "hooks/text-formatter";
import { useDataLoaderHook } from "hooks/data-loader";
import { ApiConsumer } from "api/ApiConsumer";
import { NavLink } from "react-router-dom";
import { GLOBAL } from "constants/global";
import { API_ROUTES } from "routes/api";
import { PORTAL } from "routes/portal";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppLoader } from "components/loader/app-loader";
import { AppLoaderContext } from "contexts/app-loader";
import AddEditRisk from "../../components/forms/add-edit-risk";
import Modal from "../../components/modal";

export default function RiskRegister() {
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    let { loadMultiple } = useDataLoaderHook();
    const [formDetails, setFormDetails] = useState({});
    const [kpas, setKpas] = useState([]);
    const [projects, setProjects] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [projectsReport, setProjectsReport] = useState([]);
    const [openAddEditRisk, setCloseAddEditRisk] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);

    const addProjectLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            console.log(row)
            row.normalised.description = (
                <NavLink
                    to={`${PORTAL.TASKS_BY_PROJECT}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.description}
                </NavLink>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        setLoading(true);
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }
        console.log(API_ROUTES.RISK.REGISTER)
        ApiConsumer.get(API_ROUTES.RISK.REGISTER, formDetails)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                console.log(res.data)
                setProjectsReport( addProjectLinksToTableData({
                    normalised:res.data})
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onProjectDateChange = (dates) => {
        const [start, end] = dates;

        setFormDetails((prevState) => ({
            ...prevState,
            [`project_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_end_date`]: end,
        }));
    };

    const loadData = () => {
        setLoading(true);
        ApiConsumer.get(API_ROUTES.RISK.REGISTER)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.created), new Date(b.created))
                );

                console.log(res.data);
                let normalisedData = normaliseTableData(
                    GLOBAL.RISK_HEADERS,
                    res.data
                );

                console.log(normalisedData);

                setProjectsReport(normalisedData);
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_start_date`]: sub(new Date(), { years: 1 }),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_end_date`]: add(new Date(), { years: 1 }),
        }));
    }, []);

    useEffect(() => {
        loadData()
        loadMultiple(API_ROUTES.CONTENT.KPA, setKpas);
        loadMultiple(API_ROUTES.PROJECT.PROJECTS, setProjects);
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);

        return () => {
            setDepartments([]);
        };
    }, []);
    const onCloseAddEditRisk = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setCloseAddEditRisk(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setCloseAddEditRisk(false)
            : setCloseAddEditRisk(true);
    }, [editableRow]);

    const clearSearch = () => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`kpas`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programmes`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`reporting_managers`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_status`]: [],
        }));
        setProjectsReport([]);
    };

    return (
        <>
            <Modal
                modalHeaderTag={`Risk`}
                modalHeader={`Add Risk`}
                modalContent={
                    <AddEditRisk
                        type="project"
                        obj={null}
                        route={API_ROUTES.ATTACHMENTS.PROJECTS}
                        setRowUpdated={setRowUpdated}
                        setOpen={onCloseAddEditRisk}
                    />
                }
                open={openAddEditRisk}
                setOpen={setCloseAddEditRisk}
            />
            <PageHeader pageHeaderName={`Risk Register`} />

            <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                <div className="grid grid-cols-12 space-x-4">
                    <div className="col-span-4">
                        <label className="text-sm" htmlFor="kpas">
                            KPAs:
                        </label>
                        <Select
                            id="kpas"
                            name="kpas"
                            placeholder="All kpas"
                            isMulti
                            options={kpas}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`kpas`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(kpas).length !== 0 &&
                                formDetails.kpas !== undefined
                                    ? formDetails?.kpas?.map(
                                        (selectedOption) => {
                                            return kpas[
                                                kpas.findIndex(
                                                    (option) =>
                                                        option.value ===
                                                        selectedOption
                                                )
                                                ];
                                        }
                                    )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-4">
                        <label className="text-sm" htmlFor="programmes">
                            Select projects:
                        </label>
                        <Select
                            id="projects"
                            name="projects"
                            placeholder="All projects"
                            isMulti
                            options={projects}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`projects`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(projects).length !== 0 &&
                                formDetails.projects !== undefined
                                    ? formDetails?.projects?.map(
                                        (selectedOption) => {
                                            return projects[
                                                projects.findIndex(
                                                    (option) =>
                                                        option.value ===
                                                        selectedOption
                                                )
                                                ];
                                        }
                                    )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-4">
                        <label className="text-sm" htmlFor="departments">
                            Departments:
                        </label>
                        <Select
                            id="departments"
                            name="departments"
                            placeholder="All departments"
                            isMulti
                            options={departments}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`departments`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(departments).length !== 0 &&
                                formDetails.departments !== undefined
                                    ? formDetails?.departments?.map(
                                        (selectedOption) => {
                                            return departments[
                                                departments.findIndex(
                                                    (option) =>
                                                        option.value ===
                                                        selectedOption
                                                )
                                                ];
                                        }
                                    )
                                    : 0
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="relative py-2">
                <AppLoader
                    pageContent={
                        <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 text-lg leading-6 text-gray-900 text-left items-center">
                            <span className="min-w-0 flex-1 items-center font-medium">
                                Risk Results ({projectsReport.length})
                                <br/>
                                <span
                                    className="brand-link text-sm font-normal"
                                    onClick={() => clearSearch()}
                                >
                                    Clear all search parameters
                                </span>
                            </span>

                            <div className="col-span-3">
                                <label className="text-sm" htmlFor="date_range">
                                    Date range:
                                </label>
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.project_start_date}
                                    onChange={(event) =>
                                        onProjectDateChange(event)
                                    }
                                    className="form-field"
                                    startDate={formDetails.project_start_date}
                                    endDate={formDetails.project_end_date}
                                    selectsRange
                                />
                            </div>
                            <button
                                type="button"
                                className="button flex-shrink-0 ml-2 mt-5"
                                onClick={() => searchReport()}
                            >
                                <div
                                    className="flex items-center pointer-events-none mr-1"
                                    aria-hidden="true"
                                >
                                    <MagnifyingGlassIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                Search
                            </button>

                            <button
                                type="button"
                                className="button w-auto ml-4 bg-amber-500 flex-shrink-0 hover:bg-amber-600 mt-5"
                                onClick={() => {

                                    setCloseAddEditRisk(true);
                                }}
                            >
                                <div
                                    className="flex items-center pointer-events-none mr-1"
                                    aria-hidden="true"
                                >
                                    <ExclamationTriangleIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                Add Risk
                            </button>
                        </h2>
                    }
                />
            </div>
            <Table
                tableTitle={`Risk Report Results`}
                tableHeaders={GLOBAL.RISK_HEADERS}
                tableData={projectsReport}
                fontSize="text-xs"
            />
        </>
    );
}
