import { useEffect, useState, useContext } from "react";
import { useFormValidation } from "hooks/form-validation";
import Alert from "components/alert";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import { format, compareDesc } from "date-fns";
import { AuthenticationContext } from "contexts/authentication";
import Delete from "components/delete";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import { PaperClipIcon, PencilIcon } from "@heroicons/react/24/outline";
import { AppLoaderContext } from "contexts/app-loader";
import DownloadAsPdf from "components/share/download-pdf";
import { GLOBAL } from "constants/global";
import { useTextFormatHook } from "hooks/text-formatter";

export default function TIDInformation({ type, route, obj, quarter = null }) {
    let { setLoading } = useContext(AppLoaderContext);
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [tids, setTids] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    let { normaliseTableData } = useTextFormatHook();

    const formFields = [
        { name: "definition", display: "Short definition" },
        { name: "purpose", display: "Purpose" },
        { name: "source", display: "Data/Evidence source" },
        { name: "method_of_calculation", display: "Method of calculation" },
        { name: "unit", display: "Unit of Measure" },
        { name: "limitations", display: "Data limitations" },
        { name: "indicator_type", display: "Type of indicator" },
        { name: "calculation", display: "Calculation type" },
        { name: "reporting_cycle", display: "Reporting cycle" },
        { name: "new_indicator", display: "New indicator" },
        {
            name: "annual_target",
            display: "Desired performance / Annual Target",
        },

        { name: "responsibility", display: "Indicator responsibility" },
    ];

    const validateAddTid = (isEdit) => {
        setFormSubmitted(false);
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            let formData = formDetails;

            if (formDetails.report_required) {
                formData = new FormData();
                for (const key in formDetails) {
                    formData.append(key, formDetails[key]);
                }
                formData.append("report_required", formDetails.report_required);
            }

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.TID.TID_DETAIL(editableRow.id),
                    formData
                )
                    .then((res) => {
                        loadTids();
                        setFormSubmitted(true);
                        /* setEditableRow({}); */
                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`report_required`]: null,
                        }));
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.TID.TID, formData)
                    .then((res) => {
                        loadTids();
                        setFormSubmitted(true);
                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`report_required`]: null,
                        }));
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const loadTids = () => {
        ApiConsumer.get(route(obj.id))
            .then((res) => {
                if (res.data?.length !== 0) {
                    let tidData = res.data[res.data?.length - 1];
                    setTids(tidData);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`definition`]: tidData?.definition,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`purpose`]: tidData?.purpose,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`source`]: tidData?.source,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`method_of_calculation`]:
                            tidData?.method_of_calculation,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`unit`]: tidData?.unit,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`limitations`]: tidData?.limitations,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`indicator_type`]: tidData?.indicator_type,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`calculation`]: tidData?.calculation,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`reporting_cycle`]: tidData?.reporting_cycle,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`new_indicator`]: tidData?.new_indicator,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`annual_target`]: tidData?.annual_target,
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`responsibility`]: tidData?.responsibility,
                    }));
                } else {
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`definition`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`purpose`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`source`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`method_of_calculation`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`unit`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`limitations`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`indicator_type`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`calculation`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`reporting_cycle`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`new_indicator`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`annual_target`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`responsibility`]: "",
                    }));
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };

    useEffect(() => {
        loadTids();
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [type]: obj.id,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`report_required`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`created_by`]: currentUser.id,
        }));

        return () => {
            setTids([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [obj.id, currentUser.id]);

    const TIDTableHeaders = [
        {
            key: "definition",
            value: "Short definition",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "purpose",
            value: "Purpose",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "source",
            value: "Data/Evidence source",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "method_of_calculation",
            value: "Method of calculation",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "unit",
            value: "Unit of Measure",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "limitations",
            value: "Data limitations",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "indicator_type",
            value: "Type of indicator",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "calculation",
            value: "Calculation type",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "reporting_cycle",
            value: "Reporting cycle",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "new_indicator",
            value: "New indicator",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "annual_target",
            value: "Desired performance / Annual Target",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "responsibility",
            value: "Indicator responsibility",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_required",
            value: "Report Required",
            showOnTable: true,
            showOnPrint: true,
        },
    ];

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div className="">
                <div className="space-x-3 bg-gray-100 px-4 py-6 sm:px-6">
                    <form
                        className="space-y-1 relative"
                        onSubmit={(event) => {
                            event.preventDefault();
                            validateAddTid(Object.keys(editableRow).length);
                        }}
                    >
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="definition">
                                    Short definition:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="definition"
                                    name="definition"
                                    autoComplete="definition"
                                    className={`form-field ${
                                        invalidFormDetails.definition
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`definition`]: event.target.value,
                                        }))
                                    }
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.definition
                                            ? formDetails.definition
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="purpose">
                                    Purpose:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="purpose"
                                    name="purpose"
                                    autoComplete="purpose"
                                    className={`form-field ${
                                        invalidFormDetails.purpose
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`purpose`]: event.target.value,
                                        }))
                                    }
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.purpose
                                            ? formDetails.purpose
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="source">
                                    Data/Evidence source:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="source"
                                    name="source"
                                    autoComplete="source"
                                    className={`form-field ${
                                        invalidFormDetails.source
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`source`]: event.target.value,
                                        }))
                                    }
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.source
                                            ? formDetails.source
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="method_of_calculation"
                                >
                                    Method of calculation:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="method_of_calculation"
                                    name="method_of_calculation"
                                    autoComplete="method_of_calculation"
                                    className={`form-field ${
                                        invalidFormDetails.method_of_calculation
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`method_of_calculation`]:
                                                event.target.value,
                                        }))
                                    }
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.method_of_calculation
                                            ? formDetails.method_of_calculation
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="unit">
                                    Unit of Measure:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="unit"
                                    name="unit"
                                    type="text"
                                    autoComplete="unit"
                                    className={`form-field ${
                                        invalidFormDetails.unit
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`unit`]: event.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.unit ? formDetails.unit : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="limitations"
                                >
                                    Data limitations:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="limitations"
                                    name="limitations"
                                    type="text"
                                    autoComplete="limitations"
                                    className={`form-field ${
                                        invalidFormDetails.limitations
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`limitations`]: event.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.limitations
                                            ? formDetails.limitations
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="indicator_type"
                                >
                                    Type of indicator:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="indicator_type"
                                    name="indicator_type"
                                    type="text"
                                    autoComplete="indicator_type"
                                    className={`form-field ${
                                        invalidFormDetails.indicator_type
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`indicator_type`]:
                                                event.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.indicator_type
                                            ? formDetails.indicator_type
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="calculation"
                                >
                                    Calculation type:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="calculation"
                                    name="calculation"
                                    type="text"
                                    autoComplete="calculation"
                                    className={`form-field ${
                                        invalidFormDetails.calculation
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`calculation`]: event.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.calculation
                                            ? formDetails.calculation
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="reporting_cycle"
                                >
                                    Reporting cycle:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="reporting_cycle"
                                    name="reporting_cycle"
                                    type="text"
                                    autoComplete="reporting_cycle"
                                    className={`form-field ${
                                        invalidFormDetails.reporting_cycle
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`reporting_cycle`]:
                                                event.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.reporting_cycle
                                            ? formDetails.reporting_cycle
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="new_indicator"
                                >
                                    New indicator:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="new_indicator"
                                    name="new_indicator"
                                    type="text"
                                    autoComplete="new_indicator"
                                    className={`form-field ${
                                        invalidFormDetails.new_indicator
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`new_indicator`]:
                                                event.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.new_indicator
                                            ? formDetails.new_indicator
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="annual_target"
                                >
                                    Desired performance / Annual Target:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="annual_target"
                                    name="annual_target"
                                    autoComplete="annual_target"
                                    className={`form-field ${
                                        invalidFormDetails.annual_target
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`annual_target`]:
                                                event.target.value,
                                        }))
                                    }
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.annual_target
                                            ? formDetails.annual_target
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="responsibility"
                                >
                                    Indicator responsibility:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="responsibility"
                                    name="responsibility"
                                    autoComplete="responsibility"
                                    className={`form-field ${
                                        invalidFormDetails.responsibility
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`responsibility`]:
                                                event.target.value,
                                        }))
                                    }
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.responsibility
                                            ? formDetails.responsibility
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="report_required"
                                >
                                    Report required to be uploaded: (optional)
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="report_required"
                                    name="report_required"
                                    autoComplete="report_required"
                                    className={`form-field ${
                                        invalidFormDetails.report_required
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`report_required`]:
                                                event.target.files[0],
                                        }));
                                        setFormSubmitted(false);
                                    }}
                                    //value={formDetails.report_required}
                                    type="file"
                                />
                            </div>
                        </div>
                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-right">
                                {formError}
                            </div>
                        )}
                        {formSubmitted && (
                            <Alert
                                type={`success`}
                                message={`TID information has been successfully posted.`}
                            />
                        )}
                        <div className="mt-2 float-right">
                            <button type="submit" className="button">
                                Update TID Information
                            </button>
                        </div>
                        <div className="clear-both"></div>
                    </form>
                </div>

                <div
                    className={`${
                        type === "task" ? "px-4 py-6 sm:px-6" : "py-6"
                    }`}
                >
                    {Object.keys(tids).length !== 0 ? (
                        <>
                            <div className="text-center mb-4">
                                <DownloadAsPdf
                                    tableTitle={`Project TID Information:`}
                                    tableHeaders={TIDTableHeaders}
                                    tableData={normaliseTableData(
                                        TIDTableHeaders,
                                        [tids]
                                    )}
                                    tableDataResult={obj?.name}
                                    printType={GLOBAL.PRINT.PAGE}
                                    filename={`Project TID Information: ${obj?.name}`}
                                    downloadIconText={
                                        "Download TID Information"
                                    }
                                />
                            </div>
                            <div className="clear-both"></div>
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
}
