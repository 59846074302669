import {ApiConsumer} from 'api/ApiConsumer'
import * as Sentry from '@sentry/react'

export const useDataLoaderHook = () => {
    const loadMultiple = (link, setMethod, label_column = null) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => {
                    const labelA = label_column ? a[label_column] : a.name;
                    const labelB = label_column ? b[label_column] : b.name;
                    return labelA.localeCompare(labelB);
                });

                let options = res.data.map((opt) => {
                    return {
                        value: opt.id,
                        label: label_column ? opt[label_column] : opt.name,
                    };
                });
                setMethod(options);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }


    const loadDropdown = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name))
                setMethod(res.data)
            })
            .catch((err) => {
                Sentry.captureException(err)
            })
    }

    return {
        loadMultiple, loadDropdown,
    }
}
