import {Fragment, useEffect, useState, useContext} from "react";
import {
    PRIMARY_NAVIGATION,
    IDP_NAVIGATION,
    SDBI_NAVIGATION,
    ADMIN_NAVIGATION,
    REPORTING_NAVIGATION,
    SETTINGS_NAVIGATION, RISK_REGISTER_NAVIGATION, PERFORMANCE_NAVIGATION,
} from "routes";
import {PORTAL} from "routes/portal";
import {APP_NAME, APP_LOGO, APP_LOGO_ALT} from "config";
import {Dialog, Transition} from "@headlessui/react";
import {XCircleIcon, ChartPieIcon} from "@heroicons/react/24/outline";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {GLOBAL} from "constants/global";
import {ActiveKpaContext} from "contexts/active-kpa";
import {ActiveTermContext} from "contexts/active-term";
import {AuthenticationContext} from "contexts/authentication";
import {usePermissionsHook} from "hooks/permissions";
import * as Sentry from "@sentry/react";
import {NavLink} from "react-router-dom";
import {useAuthentication} from "hooks/authentication";
import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/24/solid";
import {useTermsHook} from "hooks/terms";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
    let {currentUser} = useContext(AuthenticationContext);
    const [kpas, setKpas] = useState([]);
    const [idp, setIdp] = useState("");
    const [sdbip, setSdbip] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    let {activeKpa, setActiveKpa} = useContext(ActiveKpaContext);
    let {getCurrentTermYears} = useTermsHook();

    let {hasPermission} = useAuthentication();

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.CONTENT.KPA)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setKpas(res.data);
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.METADATA.SECTION.TYPES).then((res) => {
            setIdp(res.data.filter((type) => type.name === "IDP")[0]);
            setSdbip(res.data.filter((type) => type.name === "SDBIP")[0]);
        });
        getCurrentTermYears();
    }, []);

    useEffect(() => {
        loadData();
        return () => {
            setKpas({});
            setLoaded(false);
        };
    }, []);

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-40 lg:hidden"
                    onClose={
                        () => setSidebarOpen(false)
                    }
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-green-700">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        <XCircleIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img
                                    className="w-3/4 h-auto"
                                    src={APP_LOGO}
                                    alt={APP_LOGO_ALT}
                                />
                            </div>
                            <nav
                                className="mt-5 flex-shrink-0 h-full divide-y divide-green-800"
                                aria-label="Sidebar"
                            >
                                <div className="px-2 space-y-1">
                                    {PRIMARY_NAVIGATION.map((item, i) => (
                                        <NavLink
                                            key={i}
                                            to={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-green-800 "
                                                    : "text-green-100 hover:bg-green-600",
                                                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                            )}
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            <item.icon
                                                className="mr-2 flex-shrink-0 h-6 w-6 text-green-200"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </NavLink>
                                    ))}
                                </div>
                                <div className="mt-6 pt-6">
                                    <div className="px-2 space-y-1">
                                        {ADMIN_NAVIGATION.map((item, i) =>
                                            hasPermission(item.permission) ? (
                                                <NavLink
                                                    key={i}
                                                    to={item.href}
                                                    className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-green-100 hover:bg-green-600"
                                                >
                                                    <item.icon
                                                        className="mr-2 h-6 w-6 text-green-200"
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </NavLink>
                                            ) : null
                                        )}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow bg-green-700 pb-5 overflow-y-auto">
                    <div className="px-4 py-4 bg-gray-100 leading-5 text-center font-semibold">
                        <NavLink to="/">
                            <img
                                className="w-3/4 h-auto m-auto p-2 rounded-md mb-2 bg-white"
                                src={APP_LOGO}
                                alt={APP_LOGO_ALT}
                            />
                            {APP_NAME}
                        </NavLink>
                    </div>
                    <nav
                        className="mt-5 flex-1 flex flex-col divide-y divide-green-800 "
                        aria-label="Sidebar"
                    >
                        <div className="px-2 space-y-1">
                            {PRIMARY_NAVIGATION.map((item, i) => (
                                <NavLink
                                    key={i}
                                    to={item.href}
                                    onClick={() => setActiveKpa({})}
                                    className={classNames(
                                        item.current
                                            ? "bg-green-800 text-green-100"
                                            : "text-green-100 hover:bg-green-600",
                                        "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                                    )}
                                    aria-current={
                                        item.current ? "page" : undefined
                                    }
                                >
                                    <item.icon
                                        className="mr-2 flex-shrink-0 h-6 w-6 text-green-200"
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </NavLink>
                            ))}
                        </div>

                        {currentUser.user_type.name ===
                            GLOBAL.USER_TYPES.ADMINISTRATOR && (
                                <>
                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            <div className="text-green-500 font-medium text-xs ml-2 uppercase">
                                                Integrated Development Plan (IDP)
                                            </div>
                                            {IDP_NAVIGATION.map((item, i) =>
                                                hasPermission(item.permission) ? (
                                                    <NavLink
                                                        key={i}
                                                        to={
                                                            item.name ===
                                                            "IDP Programmes"
                                                                ? item.href +
                                                                "/type/" +
                                                                idp?.id
                                                                : item.href
                                                        }
                                                        onClick={() =>
                                                            setActiveKpa({})
                                                        }
                                                        className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                                    >
                                                        <item.icon
                                                            className="mr-2 h-6 w-6 text-green-200"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink>
                                                ) : null
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            <div className="text-green-500 font-medium text-xs ml-2 uppercase">
                                                2024 Service Delivery Budget
                                                Implementation (SDBIP)
                                            </div>
                                            {SDBI_NAVIGATION.map((item, i) =>
                                                hasPermission(item.permission) ? (
                                                    <NavLink
                                                        key={i}
                                                        to={
                                                            item.name ===
                                                            "SDBIP Programmes"
                                                                ? item.href +
                                                                "/type/" +
                                                                sdbip?.id
                                                                : item.href
                                                        }
                                                        onClick={() =>
                                                            setActiveKpa({})
                                                        }
                                                        className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                                    >
                                                        <item.icon
                                                            className="mr-2 h-6 w-6 text-green-200"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink>
                                                ) : null
                                            )}
                                            {loaded && (
                                                <div className="px-2 space-y-1">
                                                    {/* <Disclosure>
                                                    {({ open }) => (
                                                        <>
                                                            <Disclosure.Button className="flex w-full rounded-lg bg-green-800 text-green-100 px-2 py-2 text-left text-sm font-medium">
                                                                <ChevronUpIcon
                                                                    className={`${
                                                                        open
                                                                            ? "rotate-180 transform"
                                                                            : ""
                                                                    } h-5 w-5 mr-2 text-green-100`}
                                                                />
                                                                <span>
                                                                    Project
                                                                    Activity by
                                                                    KPAs
                                                                </span>
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500"> */}
                                                    {kpas.map((kpa, i) => {
                                                        return (
                                                            <Fragment key={i}>
                                                                <NavLink
                                                                    className={`group flex items-center px-2 py-2 text-sm font-medium leading-6 rounded-md text-green-100 cursor-pointer  hover:bg-green-600 ${
                                                                        activeKpa.id ===
                                                                        kpa.id
                                                                            ? "bg-green-800 "
                                                                            : null
                                                                    }`}
                                                                    key={kpa.id}
                                                                    to={`${PORTAL.SDBIP_KPIS_BY_KPA}/${kpa.id}/b57a0e6e-d21f-4c23-8185-11596a9fd56c/2024`}
                                                                    onClick={() =>
                                                                        setActiveKpa(
                                                                            kpa
                                                                        )
                                                                    }
                                                                >
                                                                    <ChartPieIcon
                                                                        className="mr-2 flex-shrink-0 h-6 w-6 text-green-200"
                                                                        aria-hidden="true"
                                                                    />{" "}
                                                                    {kpa.name}
                                                                </NavLink>
                                                            </Fragment>
                                                        );
                                                    })}
                                                    {/* </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            <div className="text-green-500 font-medium text-xs ml-2 uppercase">
                                                RISK REGISTER
                                            </div>
                                            {RISK_REGISTER_NAVIGATION.map((item, i) =>
                                                hasPermission(item.permission) ? (
                                                    <NavLink
                                                        key={i}
                                                        to={item.href}
                                                        onClick={() =>
                                                            setActiveKpa({})
                                                        }
                                                        className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                                    >
                                                        <item.icon
                                                            className="mr-2 h-6 w-6 text-green-200"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink>
                                                ) : null
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            <div className="text-green-500 font-medium text-xs ml-2 uppercase">
                                                PERFORMANCE
                                            </div>
                                            {PERFORMANCE_NAVIGATION.map((item, i) =>
                                                hasPermission(item.permission) ? (
                                                    <NavLink
                                                        key={i}
                                                        to={item.href}
                                                        onClick={() =>
                                                            setActiveKpa({})
                                                        }
                                                        className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                                    >
                                                        <item.icon
                                                            className="mr-2 h-6 w-6 text-green-200"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink>
                                                ) : null
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            <div className="text-green-500 font-medium text-xs ml-2 uppercase">
                                                ANNUAL PERFORMANCE REPORT (APR)
                                            </div>
                                            {REPORTING_NAVIGATION.map((item, i) =>
                                                hasPermission(item.permission) ? (
                                                    <NavLink
                                                        key={i}
                                                        to={item.href}
                                                        onClick={() =>
                                                            setActiveKpa({})
                                                        }
                                                        className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                                    >
                                                        <item.icon
                                                            className="mr-2 h-6 w-6 text-green-200"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink>
                                                ) : null
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            <div className="text-green-500 font-medium text-xs ml-2 uppercase">
                                                Administration
                                            </div>
                                            {ADMIN_NAVIGATION.map((item, i) =>
                                                hasPermission(item.permission) ? (
                                                    <NavLink
                                                        key={i}
                                                        to={item.href}
                                                        onClick={() =>
                                                            setActiveKpa({})
                                                        }
                                                        className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                                    >
                                                        <item.icon
                                                            className="mr-2 h-6 w-6 text-green-200"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink>
                                                ) : null
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            <div className="text-green-500 font-medium text-xs ml-2 uppercase">
                                                System Settings
                                            </div>
                                            {SETTINGS_NAVIGATION.map((item, i) =>
                                                hasPermission(item.permission) ? (
                                                    <NavLink
                                                        key={i}
                                                        to={item.href}
                                                        onClick={() =>
                                                            setActiveKpa({})
                                                        }
                                                        className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                                    >
                                                        <item.icon
                                                            className="mr-2 h-6 w-6 text-green-200"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink>
                                                ) : null
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                    </nav>
                </div>
            </div>
        </>
    );
}
