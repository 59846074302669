import {useState, useEffect, useContext} from "react";
import {useFormValidation} from "hooks/form-validation";
import {APP_ID} from "config";
import {API_ROUTES} from "routes/api";
import {GLOBAL} from "constants/global";
import {ApiConsumer} from "api/ApiConsumer";
import {useDataLoaderHook} from "hooks/data-loader";
import * as Sentry from "@sentry/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import Select from "react-select";
import {AppLoaderContext} from "contexts/app-loader";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import {format} from "date-fns";

export default function AddEditRisk({
                                        setOpen,
                                        editableRow = {},
                                        setRowUpdated,
                                    }) {
    let {setLoading} = useContext(AppLoaderContext);
    let {loadDropdown} = useDataLoaderHook();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    let {validFormDetails} = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [departments, setDepartments] = useState([])
    const [projects, setProjects] = useState([])
    const [inherent_risk, setInherentRisk] = useState([])
    const [residual_risk, setResidualRisk] = useState([])
    const [potential_loss, setPotentialLoss] = useState([])
    const [likelihood, setLikelihood] = useState([])
    const [perceivedControl, setPerceivedControl] = useState([])
    const [category, setCategory] = useState([])
    let {loadMultiple} = useDataLoaderHook();

    const formFields = [
        {name: "description", display: "Description"},
        {name: "mitigation", display: "Mitigation"},
        {name: "inherent_risk", display: "Inherent Risk"},
        {name: "residual_risk", display: "Residual Risk"},
        {name: "attachment", display: "Attachment"},
    ];

    const validateRiskForm = (isEdit) => {
        console.log(isEdit)
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            console.log(formDetails);
            console.log(isEdit)
            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.PROJECT.RISK_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.RISK.REGISTER, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            description: "",
            mitigation: "",
            inherant_ranking: "",
            residual_ranking: "",
            attachment: null,
            project: ""
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                description: editableRow.description,
                mitigation: editableRow.mitigation,
                inherant_ranking: editableRow.inherant_ranking,
                residual_ranking: editableRow.residual_ranking,
                attachment: editableRow.attachment,
                project: editableRow.project
            }));
        }

        return () => {


        };
    }, []);

    useEffect(() => {
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);
        loadMultiple(API_ROUTES.PROJECT.PROJECTS, setProjects);
        loadMultiple(API_ROUTES.RISK.INHERENT_RISK, setInherentRisk);
        loadMultiple(API_ROUTES.RISK.RESIDUAL_RISK, setResidualRisk);
        loadMultiple(API_ROUTES.RISK.POTENTIAL_LOSS, setPotentialLoss, 'severity_ranking');
        loadMultiple(API_ROUTES.RISK.LIKELIHOOD, setLikelihood,'category');
        loadMultiple(API_ROUTES.RISK.PERCEIVED_CONTROL_EFFECTIVENESS, setPerceivedControl,'category');
        loadMultiple(API_ROUTES.RISK.RISK_CATEGORY, setCategory);

        return () => {
            setDepartments([]);
        };
    }, []);

    const types = [
        {
            value: 'I',
            label: 'Internal'
        },
        {
            value: 'E',
            label: 'External'
        }
    ]

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateRiskForm(Object.keys(editableRow).length);
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <>
                            <Modal
                                modalHeaderTag={""}
                                modalHeader={`User Details: ${editableRow.created_by.first_name} ${editableRow.created_by.last_name}`}
                                modalContent={
                                    <ModalUserDetails
                                        setOpen={setOpenUserDetails}
                                        userDetailsRow={userDetailsRow}
                                    />
                                }
                                open={openUserDetails}
                                setOpen={setOpenUserDetails}
                            />
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                                by{" "}
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(
                                            editableRow.created_by
                                        );
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {editableRow.created_by.first_name +
                                        " " +
                                        editableRow.created_by.last_name}
                                </span>{" "}
                                <span className="font-bold">Last updated:</span>{" "}
                                {editableRow.normalised.updated}
                            </div>
                        </>
                    )}

                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="department">
                                    Department:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="departments"
                                    name="departments"
                                    placeholder="All departments"
                                    isMulti={false}
                                    options={departments}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        console.log(event.value)
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`departments`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="description">
                                    Project:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="projects"
                                    name="projects"
                                    placeholder="All projects"
                                    isMulti={false}
                                    options={projects}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`projects`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="description">
                                    Type:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="type"
                                    name="type"
                                    placeholder="Internal"
                                    isMulti={false}
                                    options={types}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`type`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="department">
                                    Inherent Risk:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="inherent_risk"
                                    name="inherent_risk"
                                    placeholder="Inherent Risk"
                                    isMulti={false}
                                    options={inherent_risk}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`inherent_risk`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="department">
                                    Residual Risk:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="residual_risk"
                                    name="residual_risk"
                                    placeholder="Residual Risk"
                                    isMulti={false}
                                    options={residual_risk}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`residual_risk`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="department">
                                    Potential Loss Impact:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="potiential_loss_impact"
                                    name="potiential_loss_impact"
                                    placeholder="Potiential Loss Impact"
                                    isMulti={false}
                                    options={potential_loss}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`potential_loss_impact`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="department">
                                    Likelihood:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="likelihood"
                                    name="likelihood"
                                    placeholder="Likelihood"
                                    isMulti={false}
                                    options={likelihood}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`likelihood`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="department">
                                    Perceived Control Effectiveness:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="departments"
                                    name="departments"
                                    placeholder="Control Effectiveness"
                                    isMulti={false}
                                    options={perceivedControl}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`perceived_control_effectiveness`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="department">
                                    Category:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="category"
                                    name="category"
                                    placeholder="Category"
                                    isMulti={false}
                                    options={category}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`category`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="description">
                                    Description:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="description"
                                    name="description"
                                    autoComplete="description"
                                    className={`form-field ${
                                        invalidFormDetails.description
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`description`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.description
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="mitigation">
                                    Mitigation:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="mitigation"
                                    name="mitigation"
                                    autoComplete="mitigation"
                                    className={`form-field ${
                                        invalidFormDetails.mitigation
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            mitigation: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.mitigation ? formDetails.mitigation : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="attachment">
                                    Attachment:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="attachment"
                                    name="attachment"
                                    type="file"
                                    autoComplete="attachment"
                                    className={`form-field ${
                                        invalidFormDetails.attachment
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            attachment: event.target.files[0],
                                        }));
                                    }}
                                />
                            </div>
                        </div>


                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Risk`
                                    : `Update Risk`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } risk:<br /><strong>${
                            formDetails.description
                        }</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
